// AnnouncementBanner.js
import React from 'react';
import './Banner.css'
const Banner2 = ({ message, onClose }) => {
    return (
        <>
            <style>
                {`
                        .kalsa{
                            max-width: 70%;
                        }
                    @media (max-width: 768px) {
                        .announcement-banner {
                            flex-direction: column !important;
                            align-items: flex-start !important;
                        }
                        .kalsa{
                            max-width: 90% !important;
                        }
                    }
                `}
            </style>
            <div className="announcement-banner" style={{ position: 'fixed', zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className='kalsa' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', maxWidth: "100%", paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
                        <p style={{ textAlign: 'left', display: 'block' }}>
                            TextFlow was recently acquired by
                            <a href='https://textlinksms.com' target='_blank' style={{ display: 'inline' }}>TextLink</a>, and our team is now fully dedicated to improving it (You can still continue using TextFlow uninterruptedly)
                        </p>
                        <p style={{ textAlign: 'left', display: 'block' }}>
                            TextLink is not a simple SMS API, but a service that allows you to break free from conventional SMS providers, like Twilio and TextFlow, by using your own SIM cards to send your SMS. That highly reduces TextLink's infrastructure dependence, which has a side effect of much much lower costs  (10 to 100 times).
                        </p>
                        <p style={{ textAlign: 'left', display: 'block' }}>
                            Besides that, and the API and Webhook integrations like TextFlow, TextLink offers much better tools compared to TextFlow, such as greatly improved Chat App and No-code Bulk SMS tool, as well as the ability to create subaccounts for e.g. Agencies.
                        </p>
                        <p style={{ textAlign: 'left', display: 'block' }}>
                            TextLink is also is fully compatible with <a href='https://youtu.be/HYOGQ1l3dzA?si=KZd5JPH01t_MeLG9' target='_blank'>GoHighLevel (GHL)</a>, <a href='https://youtu.be/Owc1V5n9MUE?si=gFtRwQ4uJfFAEuOa' target='_blank'>Zapier</a> and <a href='https://youtu.be/4Oc6yjlg9V8?si=8ZCP_Gn4oXQtdT6C' target='_blank'>Make.com</a>.
                        </p>
                    </div>
                </div>
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a className="close-button" onClick={onClose}>✕</a>
                </div>
                <a href='https://textlinksms.com' target='_blank' className="btn1" >
                    <i className="animation"></i>Visit TextLink<i className="animation"></i>
                </a>
            </div>
        </>
    );
};


export default Banner2;
