import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../assets/images/logo.png'
import logoDark from '../../assets/images/logo-dark.png';
import OffCanvasMenu from './OffCanvasMenu';
import MenuItems from './MenuItems';
import tfLogo from '../../assets/images/noviLogo.png';
import tfLogo2 from '../../assets/images/logoP.png';
import tfTransparentLogo from '../../assets/images/tfLogo.png';
import logoLight from '../../assets/images/logoL.png';
import Banner from '../../../../../components/Banner';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import Banner2 from '../../../../../components/Banner2';

const Header = (props) => {
    const { parentMenu, headerClass, headerNormalLogo, headerStickyLogo, signUpBtn } = props;
    const cookies = new Cookies();

    const [showBanner, setShowBanner] = useState(cookies.get("show_banner") !== 'false');

    let navigate = useNavigate();

    const [stickyHeader, setStickyHeader] = useState(false)
    let sticky = () => {
        if (window.scrollY > 150) {
            setStickyHeader(true)
        } else {
            setStickyHeader(false)
        }
    }

    window.addEventListener("scroll", sticky)
    function closeBanner(e){
        e.preventDefault();
        setShowBanner(false)
        console.log(cookies.get("show_banner"))
        cookies.set('show_banner', false, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) });

        global.bannerClosed = true
    }
    return (
        <>
            <header className={stickyHeader ? headerClass + " sc-header-sticky" : headerClass}  id="sc-header-sticky">
            {showBanner && 
                <Banner2 
                    onClose={(e) => closeBanner(e)}
                />
            }
                <div className="container-fluid" style={{paddingTop:'1px'}}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-8 col-md-4 col-sm-3">
                            <div className="sc-header-left-wraper d-flex align-items-center">
                                <div className="sc-main-logo sc-mr-60 sc-md-mr-30 text-center text-sm-start hideImg ">
                                    <div className="default-logo logo-area">
                                        <Link to="/">
                                            <img style={{width:120,height:36, marginLeft:'10%'}} src={headerNormalLogo ? tfTransparentLogo: logoLight} alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="sticky-logo logo-area">
                                        <Link to="/">
                                            <img style={{width:120,height:36, marginLeft:'10%'}}  src={headerStickyLogo ? tfTransparentLogo: tfTransparentLogo} alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="sc-main-menu d-lg-block d-none">
                                    <ul className="main-menu">
                                        <MenuItems
                                            parentMenu={parentMenu}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* OffCanvas Area  */}
                        <div className="col-lg-4 col-md-8 col-sm-9">
                            <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-end" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="header-list" >
                                    <ul className='d-flex ' style={{}}>
                                        <li className="canvas sc-pr-20 sc-sm-pr-10 text-white d-lg-none">
                                            <OffCanvasMenu></OffCanvasMenu>
                                        </li>
                                      
                                    </ul>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;