import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import aboutImage from '../../assets/images/about/about-image.png'
import aboutImage1 from '../../assets/images/about/about-img_1.png'
import snippet1 from '../../assets/images/banner/codeSnippet1.png'
import snippet2 from '../../assets/images/banner/codeSnippet2.png'

const UseYourNumbersLanding = () => {
    return (
        <>
            <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6" style={{ marginBottom: 130 }}>
                                <div className="image-area sc-md-mb-150">
                                    <div className="sc-about-image"  >

                                        <img className="about-image" src={"https://textlinksms.com/assets/img/about/chat.png"} alt="aboutImage" />
                                        <img className="about-image_1 pulse" src={"https://textlinksms.com/assets/img/about/bulkDashboard.png"} alt="About" />
                                        <div className="circle-shape pulse"></div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-about-item sc-pl-100">
                                    <div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
                                        <h2 className="heading-title">Cheaper SMS Option (Use your own numbers)</h2>

                                    </div>
                                    <div className="description sc-mb-25 sc-sm-mb-15">
                                        If you have your own pool of numbers, you can use them to setup your own sms gateway, while still being able to use all of the features of the SMS API and Bulk SMS service.
                                        Go to <a href='https://textlinksms.com' target='_blank' style={{ display: 'inline' }}>TextLink</a> to learn more.
                                        This lets you skip all of  the complexities of having to deal with Twilio and , just follow this tutorial for a quick setup:<br></br>
                                        <a href='https://youtu.be/nyVm4U5iScg' target='_blank' style={{ display: 'inline' }}>youtu.be/nyVm4U5iScg</a> (~3 minute video).
                                        <br />
                                        <br />
                                        Besides the best rates on the market, TextLink offers improved APIs and Webhooks, Chat App and No-code Bulk SMS tools, Subaccount system for Agencies, as well as a ready to use <a href='https://youtu.be/HYOGQ1l3dzA?si=KZd5JPH01t_MeLG9' target='_blank'>GoHighLevel (GHL)</a>, <a href='https://youtu.be/Owc1V5n9MUE?si=gFtRwQ4uJfFAEuOa' target='_blank'>Zapier</a> and <a href='https://youtu.be/4Oc6yjlg9V8?si=8ZCP_Gn4oXQtdT6C' target='_blank'>Make.com</a> integration,
                                    </div>
                                    <div className="btn-area sc-mt-40" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <a className="primary-btn" href='https://textlinksms.com' target='_blank' ><span>Learn more</span></a>

                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UseYourNumbersLanding;